import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  height: 100vh;
  padding: 0;

  @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
    height: auto;
    padding-top: var(--nav-height);
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 5px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Hi, my name is</h1>;
  const two = <h2 className="big-heading">Rushikesh Mashidkar</h2>;
  const three = <h3 className="small-heading">I automate & optimize workflows for seamless deployment process</h3>;
  const four = (
    <>
      <p>
        As a DevOps professional, I excel in automating and optimizing deployment 
        processes to achieve operational excellence. Presently, 
        I am committed to developing high-performance, scalable solutions at{' '}
      <a href="https://vwits.in/#" target="_blank" rel="noreferrer">
      Volkswagen Group Technology Solutions India.
      </a>
      </p>
    </>
  );
  const five = (
    <a
      className="email-link"
      href="mailto:rishikeshmashidkar@gmail.com?subject=Available%20for%20Hire&body=Hello%20Rushikesh%2C%0A%0AI%20am%20interested%20in%20hiring%20you%20for%20my%20project.%20Please%20let%20me%20know%20if%20you%20are%20available%20for%20a%20discussion.%0A%0AThank%20you!"
      target="_blank"
      rel="noreferrer">
      Hire Me 
    </a>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i} data-aos="fade-up">{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup"  data-aos="fade-up" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
